$(document).ready(function () {


    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        cache: false
    });

    // Mobile Safari in standalone mode
    if (("standalone" in window.navigator) && window.navigator.standalone) {

        // If you want to prevent remote links in standalone web apps opening Mobile Safari, change 'remotes' to true
        var noddy, remotes = false;

        document.addEventListener('click', function (event) {

            noddy = event.target;

            // Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
            while (noddy.nodeName !== "A" && noddy.nodeName !== "HTML") {
                noddy = noddy.parentNode;
            }

            if ('href' in noddy && noddy.href.indexOf('http') !== -1 && (noddy.href.indexOf(document.location.host) !== -1 || remotes)) {
                event.preventDefault();
                document.location.href = noddy.href;
            }

        }, false);
    }

    $('.navbar').affix({
        offset: {
            top: 0
        }
    })

    $('.navbar').on('affix.bs.affix affix-top.bs.affix', function (e) {
        var padding = e.type === 'affix' ? $(this).height() : '';
    });

    $('.matchheight').matchHeight();

    window.sr = ScrollReveal();
    sr.reveal('.reveal');

    //$('[data-toggle="popover"]').popover();
    /*
     * Flash messages
     */
    $('#flash-overlay-modal').modal();
    /*
     * Flash messages auto clear
     */
    $('.modal-auto-clear').on('shown.bs.modal', function () {
        // if data-timer attribute is set use that, otherwise use default (7000)
        var timer = $(this).data('timer') ? $(this).data('timer') : 7000;
        $(this).delay(timer).fadeOut(200, function () {
            $(this).modal('hide');
        });
    })


    $('a.gallery').colorbox({
        rel: 'gal',
        slideshow: true,
        slideshowSpeed: 5000,
        maxHeight: '90%',
        maxWidth: '90%'
    });

    if ($('.swiper-header').length) {
        var mySwiper = new Swiper('.swiper-header', {
            autoplay: {
                delay: 7000,
                disableOnInteraction: true,
            },
            loop: true,
            parallax: true,
            effect: 'fade',

            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            //scrollbar: '.swiper-scrollbar',
        })
    }

    if ($('.gallery-top').length) {
        var galleryTop = new Swiper('.gallery-top', {
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        var galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            centeredSlides: true,
            slidesPerView: 'auto',
            touchRatio: 0.2,
            slideToClickedSlide: true,
        });
        galleryTop.controller.control = galleryThumbs;
        galleryThumbs.controller.control = galleryTop;
    }


    $(document).on('click', '[data-toggle="lightbox"]', function (event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });

    /*
     * Add item to cart
     */
    $('button.cart.add').on('click', function () {
        var item = $(this);
        $.post("/cartapi/add", {id: item.data('id'), variant: item.data('variant'), url: item.data('url')}, function (data) {
            log('Item add to cart');
            //modalMessage(data.message.title, data.message.body, data.buttons);
            popOver(item, 'Item toegevoegd!', 'We hebben dit item toegevoegd aan je winkelwagen. <a href="/winkelwagen">Klik hier</a> om naar je winkel wagen te gaan.');
            updateNav();
        });

    })

    /*
     * Remove item from cart
     */
    $('#cart').on('click', 'button.cart.delete', function () {
        var item = $(this);
        $.post("/cartapi/delete", {id: item.data('id')}, function (data) {
            log('Item delete');
            updateAll();
        });
    })

    /*
     * Add item to cart (Item +1)
     */
    $('#cart').on('click', 'button.cart.plus', function () {
        var item = $(this);
        $.post("/cartapi/plus", {id: item.data('id')}, function (data) {
            log('Item plus 1');
            updateAll();
        });
    })

    /*
     * Subtract item from cart (Item -1)
     */
    $('#cart').on('click', 'button.cart.minus', function () {
        var item = $(this);
        $.post("/cartapi/minus", {id: item.data('id')}, function (data) {
            log('Item minus 1');
            updateAll();
        });
    })


    function updateAll()
    {
        updateNav();
        updateCart();
    }

    function updateNav()
    {
        if ($("#cartTotal").length) {
            log('Update NavBar cart');
            $.get("/cartapi/details", function (response) {
                $("#cartTotal").html(' ' + response.message.total);
            });
        }
    }

    function updateCart()
    {
        if ($("#cart").length) {
            log('Update & Show cart');
//            alert(response);
            $.get("/cartapi", function (response) {
                $("#cart").html(response);
            });
        }
    }

    /*
     * Update cart on page (re)load. Only if #div is visible in the page
     */
    updateAll();


    /*
 * Show modal message
 */
    function popOver(el, title, message)
    {
        $('.popover').hide();
        el.popover({
            'title': title,
            'content': message,
            'placement': 'auto left',
            'html': true,
            'delay': {"show": 0, "hide": 2000},
        }).popover('show');
    }


    /*
     * Show modal message
     */
    function modalMessage(title, message, buttons)
    {

        // console.log(title + ' ' + message + ' ' + data)
        $('#cart-modal .modal-title').text(title);
        $('#cart-modal .modal-body').html(message);

        // if(buttons.length()) {
        // $.each(buttons, function (index, value) {
        //     $('#cart-modal .modal-footer').html('<a href="/cart" class="btn btn-default" role="button"></a>');
        // });
        // }

        // $('#cart-modal .modal-footer').html(message);
        $('#cart-modal').modal({'show': true})
    }


    function log(msg)
    {
        try {
            console.log(msg);
        } catch (e) {
        }
    }


});
